<template>   
  <b-card-text>
    <b-row class="mb-1">
      <b-col sm="12">
        <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Concepto">
          <i class="fa fa-plus"></i> Agregar
        </b-button>
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col>
        <b-table class="mb-0"
              responsive="sm"
              head-variant="dark"
              :hover="true"
              :small="true"
              :fixed="false"
              :items="table.items"
              :fields="table.fields"
              :filter="table.filter"
              :current-page="table.currentPage"
              :per-page="table.perPage"
              v-if="table.items.length">

          <template v-slot:cell(id)="data">
            <b>{{ data.item.id }}</b>
          </template>

          <template v-slot:cell(name)="data">
            {{ data.item.name }}
          </template>

          <template v-slot:cell(iva_conditions)="data">
            <div v-if="data.item.iva_conditions">
              {{ data.item.iva_conditions.name }}
            </div>
          </template>

          <template v-slot:cell(accounting_account)="data">
            <div v-if="data.item.accounting_account">
              {{ data.item.accounting_account.code }} - {{ data.item.accounting_account.name }}
            </div>
          </template>

          <template v-slot:cell(f_action)="data">
            <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                
              <b-dropdown-header>Acciones</b-dropdown-header>                                                           
              
              <b-dropdown-item @click="edit(data.item)">
                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
              </b-dropdown-item>
              <b-dropdown-item @click="remove(data.item)">
                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
        <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
      </b-col>
    </b-row>
                        
    <b-row>
      <b-col>
        <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
          <i class="fa fa-angle-double-left"></i>
          Volver
        </b-button>                        
      </b-col>

      <b-col>
        <nav>
          <b-pagination class="pull-right mb-0"
                        size="sm"
                        pills=""
                        :total-rows="getRowCount(table.items)"
                        :per-page="table.perPage"
                        v-model="table.currentPage" />
        </nav>
      </b-col> 
    </b-row>            

    <!-- ########################### -->
    <!-- #####     MODALES     ##### -->
    <!-- ########################### -->

    <b-modal v-model="modal.form.active"
            header-bg-variant="dark"
            header-text-variant="white">

        <div slot="modal-header">
          {{this.modal.form.title}}
        </div>

        <b-row>
          <b-col md="12">
            <b-form-group label="Concepto">
              <b-form-input type="text"
                              size="sm"
                              v-model="crud.form.name"
                              required>
              </b-form-input>
            </b-form-group>
          </b-col>                    
        </b-row>

        <b-row>
          <b-col md="12">
            <FindObject render="search"
                        tag="Condición iva"
                        type="erpIvaConditions" 
                        @select-object="loadIvaConditions($event)" 
                        :valueID="crud.form.iva_conditions_id"/>      
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <FindObject render="search"
                        tag="Cuenta Contable"
                        type="accountingAccountsImputation" 
                        @select-object="loadAccountingAccounts($event)" 
                        :valueID="crud.form.accounting_accounts_id"
                        :where="conditionAccountingAccountsFilters"/>
          </b-col>
        </b-row>

        <div slot="modal-footer">
          <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
          <b-button variant="dark" @click="save()">Guardar</b-button>          
        </div>
    </b-modal>    
  </b-card-text>                                
</template>

<script>
  import serviceAPI from './../services'
  import Error from '@/handler/error'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import FindObject from '@/components/inc/find/findObject'  

  export default {
    components: {
      FindObject,        
    },  
    data: () => {
      return {      
        table : {
          items: [],
          fields: [
            {key: 'id', label: 'ID', sortable: true, class:"align-middle"},
            {key: 'name', label: 'Nombre', class:"align-middle"},
            {key: 'iva_conditions', label: 'Condición Iva', class:"align-middle"},
            {key: 'accounting_account', label: 'Cuenta Contable de Venta', class:"align-middle"},
            {key: 'f_action', label:'', class:"align-middle"},
          ],                    
          currentPage: 1,
          perPage: 50,
        },
        crud: {
          form: {
            id: 0,
            name: '',
            accounting_accounts: null,
            accounting_accounts_id: 0,
            iva_conditions: null,
            iva_conditions_id: 0,
          },
        },
        modal: {
          form: {
            active: false,
            title: ''
          },
        },
      }
    },
    mounted () {
      this.show()
    },
    computed: {
      // CONDITIONS SELECT
      conditionAccountingAccountsFilters(){
        return [
          {field: 'active', condition: true},
        ];
      },
    },
    methods: {
      getRowCount (items) {
        return items.length
      },
      show() {        
        var result = serviceAPI.obtenerConceptos()

        result.then((response) => {
          var data = response.data
          this.table.items = data
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error))
        });  
      },
      add() {
        this.crud.form.id = 0                
        this.crud.form.name = ''
        this.crud.form.accounting_accounts = null,
        this.crud.form.accounting_accounts_id = 0,
        this.crud.form.iva_conditions = null,
        this.crud.form.iva_conditions_id = 0,

        this.modal.form.title = "Nueva Concepto"
        this.modal.form.active = true
      },
      edit(item) {                
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.accounting_accounts = item.accounting_accounts,
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id,
        this.crud.form.iva_conditions = item.iva_conditions,
        this.crud.form.iva_conditions_id = item.iva_conditions_id,

        this.modal.form.title = "Editar Concepto"
        this.modal.form.active = true
      },
      remove(item) {
        this.crud.form.id = item.id
        this.crud.form.name = item.name
        this.crud.form.accounting_accounts = item.accounting_accounts,
        this.crud.form.accounting_accounts_id = item.accounting_accounts_id,
        this.crud.form.iva_conditions = item.iva_conditions,
        this.crud.form.iva_conditions_id = item.iva_conditions_id,

        this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ this.crud.form.name + '?', {
          title: 'Borrar Concepto',
          size: 'lg',
          buttonSize: 'lg',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          headerBgVariant: 'danger',
          headerTextVariant: 'white',
          hideHeaderClose: false,
          centered: true
        })
        .then(value => {
          if (value) {
            let loader = this.$loading.show();
            var result = serviceAPI.eliminarConcepto(this.crud.form.id);

            result.then((response) => {      
              this.modal.form.active = false        
              loader.hide()
              this.show()
              this.$awn.success("Registro eliminado");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          }
        })
        .catch(error => {
          this.$awn.alert(Error.showError(error));
        })
      },
      save() {
        let loader = this.$loading.show();

        if (this.crud.form.id) {
          var result = serviceAPI.editarConcepto(this.crud.form);
        } else {
          var result = serviceAPI.agregarConcepto(this.crud.form);
        }

        result.then((response) => {
          this.modal.form.active = false
          loader.hide()
          this.show()
          this.$awn.success("Datos guardados con éxito");
        })
        .catch(error => {
          loader.hide()
          this.$awn.alert(Error.showError(error));
        })
      },

      loadAccountingAccounts (object) {
        if(object){
          this.crud.form.accounting_accounts = object
          this.crud.form.accounting_accounts_id = object.id             
        } else {
          this.crud.form.accounting_accounts = null
          this.crud.form.accounting_accounts_id = 0
        }
      },

      loadIvaConditions (object) {
        if(object){
          this.crud.form.iva_conditions = object
          this.crud.form.iva_conditions_id = object.id             
        } else {
          this.crud.form.iva_conditions = null
          this.crud.form.iva_conditions_id = 0
        }
      },
    } 
  }
</script>