<template>
    <b-row>
        <b-col sm="12">
            <b-card header-tag="header" footer-tag="footer">
                <b-row>
                    <b-col>
                        <h4 id="traffic" class="card-title mb-0">Ventas - Configuración</h4>
                        <div class="small text-muted">Configuración de puntos y condiciones de ventas</div>
                    </b-col>
                </b-row>
            </b-card>

            <b-card header-tag="header" footer-tag="footer" no-body>
                <b-row>
                    <b-col>      
                        <b-tabs v-model="tabIndex" card>
                            <b-tab title="Mi Empresa" :title-link-class="linkClass(0)">
                                <tab-business></tab-business>
                            </b-tab>

                            <b-tab title="Puntos de Venta" :title-link-class="linkClass(1)">
                                <tab-points-sales></tab-points-sales>
                            </b-tab>

                            <b-tab title="Métodos de Pago" :title-link-class="linkClass(2)">
                                <tab-methods-payment></tab-methods-payment>
                            </b-tab>

                            <b-tab title="Condiciones de IVA" :title-link-class="linkClass(3)">
                                <tab-iva-conditions></tab-iva-conditions>
                            </b-tab>

                            <b-tab title="Percepciones" :title-link-class="linkClass(4)">
                                <tab-perceptions></tab-perceptions>
                            </b-tab>

                            <b-tab title="Conceptos" :title-link-class="linkClass(5)">
                                <tab-concepts></tab-concepts>
                            </b-tab>
                        </b-tabs>
                    </b-col>
                </b-row>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
    import serviceAPI from './services'
    import Error from '@/handler/error'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper' 
    import TabPointsSales from './settings/crudPointsSales'
    import TabMethodsPayment from './settings/crudMethodsPayment'
    import TabIvaConditions from './settings/crudIvaConditions'
    import TabPerceptions from './settings/crudPerceptions'
    import TabConcepts from './settings/crudConcepts'
    import TabBusiness from '@/components/inc/myBusiness/business'

    export default {
        components: {
            TabPointsSales,
            TabMethodsPayment,
            TabIvaConditions,
            TabPerceptions,
            TabConcepts,
            TabBusiness,
        },
        data: () => {
            return {      
                access: {
                    module_id: Modules.VENTAS,
                    profile_id: Profiles.PERSONAL,
                    view_reference: 'settings',
                    elements: {}
                },
                tabIndex: 0
            }
        },
        created () {     
            /* Configurar permisos de vistas y elementos */
            Helper.hasAccessView(this.access)
            /* Fin configuracion */
        }, 
        mounted () {
            
        },
        methods: {
            linkClass(idx) {
                if (this.tabIndex === idx) {
                    return ['bg-primary', 'text-light']
                } else {
                    return ['bg-light', 'text-info']
                }
            },
        } 
    }
</script>