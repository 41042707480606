<template>   
    <b-card-text>
        <b-row class="mb-1">
            <b-col sm="12">
                <b-button @click="add()" type="button" variant="primary" size="sm" class="btn-pill float-right ml-2" v-b-tooltip.hover title="Agregar Punto de Venta">
                    <i class="fa fa-plus"></i> Agregar
                </b-button>
            </b-col>
        </b-row>

                        
        <b-row class="mb-2">
            <b-col>
                <b-table class="mb-0"
                        responsive="sm"
                        head-variant="dark"
                        :hover="true"
                        :small="true"
                        :fixed="false"
                        :items="table.items"
                        :fields="table.fields"
                        :filter="table.filter"
                        :current-page="table.currentPage"
                        :per-page="table.perPage"
                        selectable
                        select-mode="single"
                        @row-selected="onRowSelected"   
                        v-if="table.items.length">                
                
                    <template v-slot:row-details="row">
                        <b-card>
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col md="12">
                                            <b-table class="mb-0"
                                                    responsive="sm"
                                                    head-variant="dark"
                                                    :hover="true"
                                                    :small="true"
                                                    :fixed="false"
                                                    :items="tableSub.items"
                                                    :fields="tableSub.fields"                            
                                                    v-if="tableSub.items.length">                                                                                     

                                            <template v-slot:cell(comprobante)="data">
                                                <b>{{data.item.type_voucher.name}}</b>
                                            </template>  

                                            <template v-slot:cell(numero)="data">
                                                <b>{{data.item.number}}</b>
                                            </template>  
                                            
                                            <template v-slot:cell(f_action)="data">                                
                                                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">
                                                <b-dropdown-header>Acciones</b-dropdown-header>
                                                <b-dropdown-item @click="editNumber(data.item)">
                                                    <i class="fa fa-pencil" style="color:blue"></i> Editar
                                                </b-dropdown-item>
                                                </b-dropdown>
                                            </template>

                                            </b-table>
                                            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </b-card>
                    </template>

                    <template v-slot:cell(id)="data">
                        <b>{{ data.item.id }}</b>
                    </template>

                    <template v-slot:cell(point_sale)="data">
                        <h5>
                            <b-badge variant="secondary" v-if="data.item.point_sale">
                                {{ data.item.point_sale.toString().padStart(4,'0') }}
                            </b-badge>                        
                        </h5>
                    </template>

                    <template v-slot:cell(name)="data">
                        {{ data.item.name }}
                    </template>

                    <template v-slot:cell(nd)="data">
                        <b-badge variant="dark" v-if="data.item.nd">
                          SI
                        </b-badge>
                    </template>
                    
                    <template v-slot:cell(certificate)="data">
                      <b v-if="data.item.certificate" class="text-primary">
                        Instalado                
                      </b>                      
                    </template>

                    <template v-slot:cell(status)="data">
                        <b v-if="data.item.certificate" class="text-primary">
                          <b-badge variant="success" v-if="data.item.afip_production">
                              PRODUCCION
                          </b-badge>    
                          <b-badge variant="danger" v-if="!data.item.afip_production">
                              HOMOLOGACION
                          </b-badge>                
                        </b>
                    </template>

                    <template v-slot:cell(f_action)="data">
                        <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right">                                                                
                            <b-dropdown-header>Acciones</b-dropdown-header>                                                           
                            
                            <b-dropdown-item @click="edit(data.item)">
                                <i class="fa fa-pencil ml-0 mr-0" style="color:blue"></i> Editar
                            </b-dropdown-item>
                            <b-dropdown-item @click="remove(data.item)">
                                <i class="fa fa-trash mr-0 ml-0" style="color:red"></i> Eliminar
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
            </b-col>
        </b-row>            
            
        <b-row>
            <b-col>
                <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
                    <i class="fa fa-angle-double-left"></i>
                    Volver
                </b-button>                        
            </b-col>

            <b-col>
                <nav>
                <b-pagination class="pull-right mb-0"
                                size="sm"
                                pills=""
                                :total-rows="getRowCount(table.items)"
                                :per-page="table.perPage"
                                v-model="table.currentPage" />
                </nav>
            </b-col> 
        </b-row>            

        <!-- ########################### -->
        <!-- #####     MODALES     ##### -->
        <!-- ########################### -->

        <b-modal v-model="modal.form.active"
                header-bg-variant="dark"
                header-text-variant="white">

            <div slot="modal-header">
                {{this.modal.form.title}}
            </div>

            <b-row>
                <b-col md="12" class="mb-3">       
                  <b-form-group label="Tipo de Punto de Venta" description="Si la opción se activa, los movimientos realizados, no se declaran.">         
                    <b-form-checkbox v-model="crud.form.nd" 
                                      switch 
                                      size="sm"                                      
                                      @input="changeCertificate()">
                      ND
                    </b-form-checkbox>                      
                  </b-form-group>      
                </b-col>                                 
                <b-col md="12">
                    <b-form-group label="Punto de venta">
                      <b-form-input type="number"
                                      size="sm"
                                      v-model="crud.form.point_sale"
                                      required>
                      </b-form-input>
                    </b-form-group>
                </b-col> 

                <b-col md="12">
                    <b-form-group label="Referencia">
                      <b-form-input type="text"
                                      size="sm"
                                      v-model="crud.form.name"
                                      required>
                      </b-form-input>
                    </b-form-group>
                </b-col> 

                <b-col md="12" v-if="!crud.form.nd">
                    <b-form-group label="Certificado">
                        <b-form-textarea                                
                            v-model="crud.form.certificate"
                            size="sm"
                            placeholder="Ingresar el certificado">
                        </b-form-textarea>
                    </b-form-group>
                </b-col> 

                <b-col md="12" v-if="!crud.form.nd">
                    <b-form-group label="Clave Privada">
                        <b-form-textarea                                
                            v-model="crud.form.private_key"
                            size="sm"
                            placeholder="Ingresar la clave privada">
                        </b-form-textarea>
                    </b-form-group>
                </b-col>

                <b-col md="6" class="mb-3" v-if="!crud.form.nd">
                    <b-form-group label="Tipo de Venta">
                        <b-form-select v-model="crud.form.type_sale" :options="arr.select.type_sale"></b-form-select>
                    </b-form-group>
                </b-col>
                
                <b-col md="6" class="mb-3" v-if="!crud.form.nd">
                  <b-form-group label="¿En Producción?">
                    <b-form-checkbox v-model="crud.form.afip_production" 
                                      switch 
                                      size="sm">
                        Producción
                    </b-form-checkbox>                      
                  </b-form-group>      
                </b-col>
            </b-row>

            <div slot="modal-footer">
                <b-button variant="outline-secondary" class="mr-1" @click="modal.form.active=false">Cancelar</b-button>
                <b-button variant="dark" @click="save()">Guardar</b-button>          
            </div>
        </b-modal>

        <!-- NUMERATION -->
        <b-modal v-model="modal.formNumeration.active"              
                 header-bg-variant="dark"
                 header-text-variant="white">

            <div slot="modal-header">
                {{this.modal.formNumeration.title}}
            </div>
                    
            <b-row>
                <b-col md="12">
                    <b-form-group label="Número">
                        <b-form-input type="number"
                                      min="0"
                                      size="sm"
                                      v-model="crud.formNumeration.number"
                                      required>
                        </b-form-input>
                    </b-form-group> 
                </b-col>
            </b-row>  
            
            <div slot="modal-footer">
                <b-button variant="outline-secondary" class="mr-1" @click="modal.formNumeration.active=false">Cancelar</b-button>
                <b-button variant="dark" @click="saveNumber()">Guardar</b-button>          
            </div>
        </b-modal> 

    </b-card-text>                                
</template>

<script>
    import serviceAPI from './../services'
    import Error from '@/handler/error'
    import Profiles from '@/config/profiles'
    import Modules from '@/config/modules'
    import Helper from '@/handler/helper' 

    export default {
        data: () => {
          return {      
            table : {
              items: [],
              fields: [
                {key: 'id', label: 'ID', sortable: true, class:"align-middle"},                        
                {key: 'nd', label: 'ND', class:"align-middle"},      
                {key: 'name', label: 'Nombre', class:"align-middle"},      
                {key: 'point_sale', label: 'Punto de Venta', class:"align-middle text-center"},      
                {key: 'certificate', label: 'Certificado', class:"align-middle text-center"},
                {key: 'status', label: 'Estado', class:"align-middle text-center"},
                {key: 'f_action', label:'', class:"align-middle"},
              ],
              currentPage: 1,
              perPage: 50,
            },
            tableSub : {
              items: [],
              fields: [
                {key: 'comprobante', label: 'Comprobante', class:'align-middle'},
                {key: 'numero', label: 'Número', class:'align-middle'},
                {key: 'f_action', label:'', class:'align-middle'},
              ],
              filter: null,
              mostrarFiltros: false,
              currentPage: 1,
              perPage: 50,
            },
            arr: {
              numeration: [],
              select: {
                type_sale: [
                  { text: 'Bienes', value: 'bienes' },
                  { text: 'Servicios', value: 'servicios' },
                  { text: 'Bienes y Servicios', value: 'bienes_servicios' },
                ],
              }
            },
            crud: {
              form: {
                id: 0,
                point_sale: 0,
                name: '',
                nd: false,
                certificate: '',
                pivate_key: '',
                production: 0,
                type_sale: 'bienes',
              },
              formNumeration: {
                points_sales_id: 0,
                types_vouchers_id: 0,
                number: 0,
              }
            },
            modal: {
              form: {
                active: false,
                title: ''
              },
              formNumeration: {
                active: false,
                title: ''
              },
            },
          }
        },
        mounted () {
          this.show()
        },
        methods: {
          getRowCount (items) {
            return items.length
          },
          onRowSelected(item) {      
            this.arr.numeration.forEach((element, index) => {
              if(item.length){
                if(element.id == item[0].id) {
                  this.openDetail(index)
                }
              }
            }) 
          },     
          openDetail(index) {
            this.table.rowSelected = index
            this.show()
          }, 
          show() {        
            var result = serviceAPI.obtenerPuntosVentas()

            result.then((response) => {
              var data = response.data
              this.table.items = data
              this.arr.numeration = data

              if(this.table.rowSelected!=null) {
                this.table.items[this.table.rowSelected]._showDetails = true

                if(this.table.items[this.table.rowSelected].numeration) {
                  this.tableSub.items = this.table.items[this.table.rowSelected].numeration
                }
              }
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error))
            });  
          },
          add() {
            this.crud.form.id = 0
            this.crud.form.point_sale = 0
            this.crud.form.name = ''
            this.crud.form.nd = false
            this.crud.form.certificate = ''
            this.crud.form.private_key = ''
            this.crud.form.production = 0
            this.crud.form.type_sale = 'bienes'

            this.modal.form.title = "Nuevo Punto de Venta"
            this.modal.form.active = true
          },
          edit(item) {                
            this.crud.form.id = item.id
            this.crud.form.point_sale = item.point_sale   
            this.crud.form.name = item.name
            this.crud.form.nd = item.nd
            this.crud.form.certificate = item.certificate
            this.crud.form.private_key = item.private_key
            this.crud.form.production = item.production
            this.crud.form.type_sale = item.type_sale

            this.modal.form.title = "Editar Punto de Venta"
            this.modal.form.active = true
          },
          remove(item) {
            this.crud.form.id = item.id

            this.$bvModal.msgBoxConfirm('¿Desea borrar el item (' + this.crud.form.id + ') - '+ item.name + ' ['+ item.point_sale +']?', {
              title: 'Borrar Punto de Venta',
              size: 'lg',
              buttonSize: 'lg',
              okVariant: 'danger',
              okTitle: 'SI',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              headerBgVariant: 'danger',
              headerTextVariant: 'white',
              hideHeaderClose: false,
              centered: true
            })
            .then(value => {
              if (value) {
                let loader = this.$loading.show();
                var result = serviceAPI.eliminarPuntosVentas(this.crud.form.id);

                result.then((response) => {      
                  this.modal.form.active = false        
                  loader.hide()
                  this.show()
                  this.$awn.success("Registro eliminado");
                })
                .catch(error => {
                  loader.hide()
                  this.$awn.alert(Error.showError(error));
                })
              }
            })
            .catch(error => {
              this.$awn.alert(Error.showError(error));
            })
          },
          save() {                
            if(this.crud.form.point_sale) {
              if(this.crud.form.point_sale <= 0) {                    
                this.$awn.alert("El punto de venta es obligatorio")
                return false
              }
            }

            let loader = this.$loading.show();
            if (this.crud.form.id) {
              var result = serviceAPI.editarPuntosVentas(this.crud.form);
            } else {
              var result = serviceAPI.agregarPuntosVentas(this.crud.form);
            }

            result.then((response) => {
              this.modal.form.active = false
              loader.hide()
              this.show()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          },
          changeCertificate() {              
            this.crud.form.certificate = ''
          },
          editNumber(item) {
            this.crud.formNumeration.points_sales_id = item.points_sales_id        
            this.crud.formNumeration.types_vouchers_id = item.types_vouchers_id
            this.crud.formNumeration.number = item.number

            this.modal.formNumeration.title = 'Editar numeración de "' + item.type_voucher.name + '"'
            this.modal.formNumeration.active = true
          },
          saveNumber() {
            this.crud.formNumeration.number = parseInt(this.crud.formNumeration.number)

            let loader = this.$loading.show();

            var result = serviceAPI.editarNumeracion(this.crud.formNumeration);                

            result.then((response) => {
              this.modal.formNumeration.active = false
              loader.hide()
              this.show()
              this.$awn.success("Datos guardados con éxito");
            })
            .catch(error => {
              loader.hide()
              this.$awn.alert(Error.showError(error));
            })
          },
        } 
    }
</script>